/* Sticky footer styles
-------------------------------------------------- */

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#content-div {
  flex: 1
}

.footer {
  width: 100%;
  background-color: $colour-page-background;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $spacer;
}

.footer__item {
  font-size: 90%;
  color: $text-muted;

  & + .footer__item:before {
    color: $text-muted !important;
    content: "•";
    margin: 0 0.5rem;
    display: inline-block;
    text-decoration: none;
  }
}

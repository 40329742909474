body {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.body-bold {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.heading-1 {
  font-family: 'ABC Whyte Inktrap';
  font-size: 48px;
  font-style: normal;
  font-weight: 300;
  line-height: 64px;
  margin: 0;
}

.heading-3 {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 142.857% */
}

.error {
  padding: 0;
  background: $colour-page-background;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.error__logo {
  padding: 2rem;
}

.error__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  align-items: center;

  .error__image {
    margin-bottom: 1rem;
  }

  .error__subtitle {
    color: theme-color('primary');
    margin-top: 0;
    margin-bottom: 0;
  }

  .error__message {
    color: theme-color('secondary');
  }

  .btn i {
    svg {
      width: 20px;
      height: 20px;
      vertical-align: text-bottom;
    }

    margin-right: .5rem;
  }
}

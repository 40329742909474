$colour-primary: #5d06cb;
$colour-secondary: #706A73;
$colour-page-background: #f7f6f7;
$colour-text-default: #1e1a23;
$colour-color-secondary-red: #c22b22;

$nav-height: 63px;

$font-size-base: .875rem;

$theme-colors: (
  "primary": $colour-primary,
  "secondary": $colour-secondary,
);

$body-bg: $colour-page-background;

$bg-dark: $colour-text-default;
